import { vsDark as codeTheme } from '@code-surfer/themes';
import { Icon } from 'antd';
import { CodeSurfer } from 'code-surfer';
import { css } from '@emotion/core';
import { Appear, Notes, Image } from 'mdx-deck';
import { PresentationTweet } from "../../../../src/components/PresentationTweet";
import helmLogo from "../../../../src/decks/k8s-deploy/helm.svg";
import React from 'react';
export default {
  codeTheme,
  Icon,
  CodeSurfer,
  css,
  Appear,
  Notes,
  Image,
  PresentationTweet,
  helmLogo,
  React
};